import Navbar from '../components/NavbarComponent.vue';
import FederalView from '../views/FederalView.vue';
// import Ontario from '../components/OntarioComponent.vue';
// import Quebec from '../components/QuebecComponent.vue';
import FooterComponent from '../components/FooterComponent.vue';
// import { RouterLink, RouterView } from 'vue-router';
// import axios from "axios";

// const routes = {
//     '/': Home,
//     '/about': About
//   }

export default {
    name: 'App',
    components: {
        Navbar,
        FederalView,
        // Ontario,
        // Quebec,
        FooterComponent,
        // RouterView,
        // RouterLink
    },
    data() {
        let dataList = [[], []];

        return {
            parsedData: dataList,
            currentView: "FederalView",
            componentKey: 0,
        }
    }
}