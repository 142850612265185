<template>
  <div>
    <div class="bodyElement header">
      <h1 class="bigTitle">{{ $t("FederalView.bigTitle1") }}</h1>
      <!--{{ currentView }} Poll Trends-->
      <div>{{ $t("FederalView.signature") }}</div>
    </div>
    <div class="bodyElement">
      <OpinionGraph currentView="FederalView" />
    </div>
    <div class="bodyElement header">
      <h1 class="bigTitle">{{ $t("FederalView.bigTitle2") }}</h1>
    </div>
    <div class="bodyElement">
      <ForecastGraph currentView="FederalView" />
    </div>
  </div>
</template>
<script>
import OpinionGraph from "../components/OpinionGraph.vue";
import ForecastGraph from "../components/ForecastGraph.vue";

export default {
  name: "FederalComponent",
  components: {
    OpinionGraph,
    ForecastGraph,
  },
  data() {
    return {
      currentView: String,
    };
  },
};
</script>
<style src="../css/app.css"></style>
