<template>
  <div class="infoBox infoColumn">
    <div class="">
      {{ $t(party) }}
    </div>
    <div class="seatNumber" :style="numStyle">
      {{ electionValue }}
    </div>
    <div class="deltaNum">
      <span v-if="difference >= 0">+</span>{{ difference }}
      {{ $t("FederalView.seats") }}<span v-if="difference != 1">s</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "PartySeat",
  props: {
    currentView: String,
    electionParty: String,
    electionValue: Number,
    difference: Number,
  },
  data() {
    // console.log(this.electionValue);
    let partyHex = this.$t(
      this.currentView + "." + this.electionParty + "-colour"
    );
    return {
      party: this.currentView + "." + this.electionParty,
      numStyle: {
        color: partyHex,
      },
    };
  },
};
</script>
<style scoped>
.infoBox {
  width: 30% !important;
  display: flex;
  font-weight: bold;
  margin: 5px;
  padding: 5px;
  border: solid 1px lightgray;
  border-radius: 5px;
  background: #eeeeee;
}
.infoColumn {
  flex-direction: column;
}
.seatNumber {
  font-size: 40px;
}
.deltaNum {
  font-weight: normal;
}
@media only screen and (max-width: 810px) {
  .infoBox {
    width: 45% !important;
  }
}
@media only screen and (max-width: 810px) {
  .infoBox {
    width: 100% !important;
  }
}
</style>
