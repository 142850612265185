<template>
  <div class="graphLine">
    <b v-if="graphWidth >= 500" class="partyName">{{ currentParty }}</b>
    <b v-else class="smallPartyName">{{ electionParty }}</b>
    <div id="backLine" :style="backLineWidth">
      <span :style="lineStyle" class="lineBox">
        <span class="lineColour" :style="innerStyle">
          <div
            v-if="showBigRange && electionMax != electionMin"
            id="minDot"
            class="dotClass"
            :style="innerColour"
          >
            <span class="dotText minText">{{ electionMin }}</span>
          </div>
          <div
            v-if="!showBigRange || electionMax == electionMin"
            class="dotClass"
            :style="midDot"
          >
            <span class="dotText">{{ electionValue }}</span>
          </div>
          <div
            v-if="showBigRange && electionMax != electionMin"
            id="maxdot"
            class="dotClass"
            :style="innerColour"
          >
            <span class="dotText maxText">{{ electionMax }}</span>
          </div>
        </span>
      </span>
    </div>
    <div id="verticalLine" :style="verticalLineMargin">
      <span v-if="index == 0" id="majorityText">172 for majority</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "SeatRangeGraph",
  props: {
    electionParty: String,
    currentView: String,
    electionValue: Number,
    electionMin: Number,
    electionMax: Number,
    graphMax: Number,
    index: Number,
    showBigRange: Boolean,
  },
  created() {
    window.addEventListener("resize", this.changedWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.changedWidth);
  },
  data() {
    this.getGraphWidth();

    return this.redrawComponent();
  },
  methods: {
    changedWidth() {
      this.getGraphWidth();
      this.redrawComponent();
    },
    getGraphWidth() {
      if (window.innerWidth >= 810) {
        this.graphWidth = 500;
      } else if (window.innerWidth >= 510 && window.innerWidth < 810) {
        this.graphWidth = 400;
      } else if (window.innerWidth >= 320 && window.innerWidth < 510) {
        this.graphWidth = 250;
      } else {
        this.graphWidth = 200;
      }
      this.redrawComponent();
      return this.graphWidth;
    },
    redrawComponent() {
      let currentParty = this.$t(this.currentView + "." + this.electionParty);
      let partyHex = this.$t(
        this.currentView + "." + this.electionParty + "-colour"
      );
      let lineWidth =
        (this.electionMax / (this.graphMax + 50)) * (this.graphWidth + 50);
      let showLine =
        ((this.electionMax - this.electionMin) / this.graphMax) *
        (this.graphWidth + 50);
      let marginLeft =
        (this.electionValue - this.electionMin) /
        (this.electionMax - this.electionMin);
      marginLeft = marginLeft * showLine - 6;

      // let horizontalMargin = 170;
      // if (this.graphWidth < 500) horizontalMargin = 70;

      let verticalCalc = 170;
      if (this.graphWidth < 500 && this.graphWidth >= 400) verticalCalc = 145;
      else if (this.graphWidth < 400 && this.graphWidth >= 200)
        verticalCalc = 170;
      else if (this.graphWidth < 200) verticalCalc = 100;

      let verticalMargin =
        ((verticalCalc + 35) / this.graphMax) * this.graphWidth;
      return {
        currentParty: currentParty,
        lineStyle: {
          width: lineWidth + "px",
        },
        innerStyle: {
          width: showLine + "px",
          backgroundColor: partyHex + "70",
        },
        innerColour: {
          backgroundColor: partyHex,
        },
        midDot: {
          backgroundColor: partyHex,
          marginLeft: marginLeft + "px",
        },
        backLineWidth: {
          width: this.graphWidth - 20 + "px",
          // marginLeft: horizontalMargin + "px",
        },
        verticalLineMargin: {
          marginLeft: verticalMargin + "px",
        },
      };
    },
  },
};
</script>
<style>
#backLine {
  height: 1px;
  background: lightgray;
  /* margin-left: 170px; */
  transform: translate(0, 14px);
  z-index: 0;
}
#verticalLine {
  height: 50px;
  width: 1px;
  background: lightgray;
  position: absolute;
  z-index: 0;
}
#majorityText {
  font-size: 11px;
  position: absolute;
  transform: translate(-50%, -120%);
}
.graphLine {
  display: flex;
  height: 30px;
  margin: 10px;
}
.rangeNumber {
  padding: 5px;
}
.lineBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 50px;
  width: 100%;
}
.partyName {
  width: 150px;
  text-align: left;
  padding-right: 30px;
}
.smallPartyName {
  text-align: left;
  margin-left: 10px;
  width: 65px;
}
.lineColour {
  z-index: 1;
  height: 6px;
  display: flex;
  border-radius: 20px;
  justify-content: space-between;
  transform: translateY(-30%);
}
.dotClass {
  min-height: 12px;
  min-width: 12px;
  max-height: 12px;
  max-width: 12px;
  border-radius: 50%;
  display: flex;
  opacity: 100%;
  margin-left: -7px;
  margin-top: -4px;
}
.dotText {
  font-size: 12px;
  transform: translate(0, -15px);
}
.minText {
  transform: translate(-130%, 0);
}
.maxText {
  transform: translate(13px, 0);
}
/* #minDot { */
/* } */
</style>
