<template>
  <div id="footer">
    <div id="copyright">
      <img
        src="../assets/greyLogo.png"
        width="25"
        height="25"
        alt="Politrend grey logo"
      />
      <div style="margin-left: 10px">© 2023 Politrend</div>
    </div>
    <div id="links">
      <img
        src="../assets/footer/twitterLogo.png"
        width="15"
        height="15"
        alt="Twitter link logo"
      />
      <a class="link" href="https://twitter.com/victonothing" target="_blank"
        >Twitter</a
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "FooterComponent",
};
</script>
<style scoped>
#footer {
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: #212121;
  display: flex;
  justify-content: center;
  align-items: center;
}
#copyright {
  position: absolute;
  left: 20px;
  color: white;
  font-size: 13px;
  font-family: Merriweather, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}
#links {
  position: absolute;
  right: 40px;
  color: white;
  font-size: 13px;
  font-family: Merriweather, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}
.link {
  margin-left: 5px;
  color: white;
  text-decoration: none;
}
.link:hover {
  text-decoration: underline;
}
</style>
