<template>
  <div>
    <div v-if="loaded" class="partySeatList">
      <PartySeat
        v-for="index of indexCount"
        :electionValue="parseInt(nextElection[index])"
        :electionParty="electionParties[index]"
        :difference="
          parseInt(nextElection[index]) - parseInt(previousElection[index])
        "
        :currentView="currentView"
        :key="index"
      />
    </div>
    <div class="chartBox" style="padding-bottom: 20px">
      <div class="forecastGrid">
        <div>
          <div class="smallTitle textBox">
            {{ $t("FederalView.smallTitle1") }}
          </div>
          <div class="textBox">
            {{ $t("FederalView.text1") }}
          </div>
        </div>
        <div v-if="showProjection" class="rangeGraph">
          <div id="buttonBox">
            <div
              class="rangeButton"
              :class="{ darkBackground: !showBigRange }"
              @click="changeRange('projected')"
            >
              {{ $t("FederalView.fgGraphButton1") }}
            </div>
            <div
              class="rangeButton"
              :class="{ darkBackground: showBigRange }"
              @click="changeRange('likely')"
            >
              {{ $t("FederalView.fgGraphButton2") }}
            </div>
          </div>
          <div class="graphText" v-if="!showBigRange">
            <b>{{ $t("FederalView.textTitle1") }}</b
            >{{ $t("FederalView.text2") }}
          </div>
          <div class="graphText" v-if="showBigRange">
            <b>{{ $t("FederalView.textTitle1") }}</b
            >{{ $t("FederalView.text3") }}
          </div>
          <SeatRangeGraph
            v-for="index of indexCount"
            :electionValue="parseInt(nextElection[index])"
            :electionMin="parseInt(minElection[index])"
            :electionMax="parseInt(maxElection[index])"
            :electionParty="electionParties[index]"
            :graphMax="Math.max(...maxElection)"
            :currentView="currentView"
            :showBigRange="showBigRange"
            :currentWidth="pageWidth"
            :index="index"
            :key="index"
          />
        </div>
      </div>
      <div class="smallTitle textBox">{{ $t("FederalView.smallTitle2") }}</div>
      <div class="textBox">
        {{ $t("FederalView.text4") }}
      </div>
      <div class="smallTitle">{{ $t("FederalView.smallTitle3") }}</div>
      <div class="seatRow">
        <SeatProjection
          v-for="index of indexCount"
          :electionValue="parseInt(previousElection[index])"
          :electionParty="electionParties[index]"
          :difference="
            parseInt(nextElection[index]) - parseInt(previousElection[index])
          "
          :currentView="currentView"
          :flip="true"
          :key="index"
        />
      </div>
      <div v-if="showProjection" class="seatRow">
        <SeatProjection
          v-for="index of indexCount"
          :electionValue="parseInt(nextElection[index])"
          :electionParty="electionParties[index]"
          :difference="
            parseInt(nextElection[index]) - parseInt(previousElection[index])
          "
          :currentView="currentView"
          :flip="false"
          :key="index"
        />
      </div>
      <div class="smallTitle">{{ $t("FederalView.smallTitle4") }}</div>
    </div>
    <div class="additional" style="text-align: left">
      <span style="font-weight: bold">{{ $t("FederalView.textTitle2") }}</span
      >{{ $t("FederalView.text5") }}
      <!--If a party had
      10% of the general vote in 2015, 20% in 2019, 30% in 2021, and is now
      polling at 40%, the result of this party in every 2015 riding will be x4,
      the result of this party in 2019 will be x2 and the result of the party in
      2021 will be x1.5. The weight of the elections are .5 for the most recent,
      .33 for the next and .17 for the oldest. <br /><br />
      The amount of votes attributed to this party in a specific riding will
      thus be:
      <b
        >(0.17 x 4 x 2015 votes) + (0.33 x 2 x 2019 votes) + (0.5 x 1.5 x 2021
        votes)</b
      > -->
    </div>
  </div>
</template>
<script>
import SeatProjection from "../components/SeatProjection.vue";
import SeatRangeGraph from "../components/SeatRangeGraph.vue";
import PartySeat from "../components/PartySeatComponent.vue";
import axios from "axios";

export default {
  name: "ForecastGraph",
  components: {
    SeatProjection,
    SeatRangeGraph,
    PartySeat,
  },
  props: {
    currentView: String,
  },
  created() {
    window.addEventListener("resize", this.changeGraphWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.changeGraphWidth);
  },
  data() {
    const electionParties = ["LPC", "CPC", "NDP", "BQ", "GPC", "PPC"];
    const previousElection = [160, 119, 25, 32, 2, 0];
    const nextElection = [];
    const indexCount = [0, 1, 2, 3, 4, 5];

    return {
      pageWidth: this.changeGraphWidth(),
      electionParties: electionParties,
      previousElection: previousElection,
      nextElection: nextElection,
      minElection: [],
      maxElection: [],
      indexCount: indexCount,
      showProjection: false,
      showBigRange: false,
      loaded: true,
    };
  },
  mounted() {
    axios
      .get(
        "https://docs.google.com/spreadsheets/d/e/2PACX-1vSHdxj1KPFMHP5gT1ybNfd_-N_7QikiUQ1zMKJgGb4xZjwPY4DcRE6xjWHlWqgbhawjk8ZO6I52MakV/pubhtml?gid=1804583268&single=true"
      )
      .then((response) => {
        var values = this.parseData(response.data);
        this.electionParties = values[0];
        this.nextElection = values[2];
        this.minElection = values[3];
        //this.maxElection = values[4];
        for (let value of values[4]) {
          this.maxElection.push(parseInt(value));
        }
        //this.$forceUpdate(); // add forceUpdate
      });
  },
  methods: {
    changeRange(range) {
      if (range == "likely") {
        this.showBigRange = true;
      } else if (range == "projected") {
        this.showBigRange = false;
      }
    },
    changeGraphWidth() {
      if (window.innerWidth > 810) {
        this.pageWidth = 0;
      } else if (window.innerWidth > 510) {
        this.pageWidth = 1;
      } else if (window.innerWidth > 320) {
        this.pageWidth = 2;
      } else {
        this.pageWidth = 3;
      }
      return this.pageWidth;
    },
    parseData(data) {
      var returnData = [];
      data = data.substring(data.indexOf("Mapoll : ExportTable"));
      var table = data.split(/<.*?>/);
      var step = 0;
      for (var item of table) {
        if (item == "Published by ") {
          this.showProjection = true;
          return returnData;
        }
        if (!item.length == 0) {
          if (
            item == "Party" ||
            item == "Poll" ||
            item == "Seat" ||
            item == "-low" ||
            item == "-high"
          ) {
            if (step > 0) {
              returnData[step - 1].pop();
            }
            returnData.push([]);
            step++;
          } else if (step > 0) {
            returnData[step - 1].push(item);
          }
        }
      }

      return returnData;
    },
  },
};
</script>
<style>
.forecastGrid {
  display: flex;
  align-items: center;
  justify-content: center;
}
.partyMargins {
  width: 640px;
}
.textBox {
  padding: 5px 20px;
  text-align: left;
  font-size: 18px;
}
.smallText {
  padding: 0 20px;
  font-size: 14px;
}
.smallTitle {
  font-weight: bold;
  font-size: 20px;
  padding-top: 15px;
}
.seatRow {
  display: flex;
  flex-direction: row;
  padding: 0 10px;
}
.rangeGraph {
  /* min-width: 600px; */
  height: 400px;
  display: flex;
  flex-direction: column;
  align-content: center;
  /* margin: 20px; */
}
#buttonBox {
  margin: 0 20px;
  display: flex;
}
.darkBackground {
  background: lightgray;
}
.rangeButton {
  padding: 5px 10px;
  margin: 10px 3px;
  border: solid 1px lightgray;
  border-radius: 5px;
  cursor: pointer;
}
.rangeButton:hover {
  background: lightgray;
}
.rangeButton:active {
  background: rgb(184, 184, 184);
}
.graphText {
  text-align: left;
  margin: 0 20px 20px 20px;
  /* margin-bottom: 20px; */
  min-height: 44px;
}
.partySeatList {
  display: flex;
  justify-content: space-between;
  align-items: space-between;
}
@media only screen and (max-width: 1060px) {
  .forecastGrid {
    flex-direction: column;
  }
  .partySeatList {
    flex-wrap: wrap;
  }
}
</style>
