export default {
    name: 'Navbar',
    props: ['toView'],
    data() {
        return {
          langs: [
            { code: "en", text: "EN" },
            { code: "fr", text: "FR" },
          ],
        };
      },
    methods: {
        changeLang() {
            console.log("hello world");
            console.log(this.$t.loadLocaleMessages());
        }
    },
}